var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{ref:"searchFormRef",attrs:{"keyword-placeholder":"姓名、学号、录取编号","queryInfo":_vm.queryInfo,"tool-list":['grade', 'stuStatus','college','major','keyword'],"insert-select-all":['stuStatus']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"学制","label-width":"60px"}},[_c('el-select',{attrs:{"clearable":"","size":"small"},model:{value:(_vm.queryInfo.lengthOfSchooling),callback:function ($$v) {_vm.$set(_vm.queryInfo, "lengthOfSchooling", $$v)},expression:"queryInfo.lengthOfSchooling"}},_vm._l((_vm.$store.state.systemParam.lengthOfSchoolingType),function(ref){
var paramName = ref.paramName;
var paramValue = ref.paramValue;
return _c('el-option',{key:paramValue,attrs:{"label":paramName,"value":Number(paramValue)}})}),1)],1)],1),_c('div',{staticClass:"header-button"},[_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":_vm.exportStudentInfo}},[_vm._v("导出学生数据")]),_c('upload-excel',{attrs:{"download-title":"下载老生导入模板","import-title":"批量导入老生","permit-key":['stu:edit'],"loading":_vm.loading,"api":_vm.$http.importExcelHttp.importOldStudent},on:{"update:loading":function($event){_vm.loading=$event},"on-download":function($event){return _vm.$http.exportExcelHttp.exportOldStudentTemplate()},"on-success":function($event){return _vm.renderTable(1)}}})],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"40"}}),_c('el-table-column',{attrs:{"prop":"examineNo","label":"录取编号","width":"90"}}),_c('el-table-column',{attrs:{"prop":"stuNo","label":"学号","width":"150","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.stuNo ? row.stuNo : (row.stuStatus === 0 ? '未注册' : ''))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"姓名","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showSubDialog('visibleOnlyReadDialog','onlyReadDialogRef',row.id,row)}}},[_vm._v(" "+_vm._s(row.stuName)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"学籍状态","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$http.getParamName(row.stuStatus, _vm.$store.state.systemParam.stuStatus))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"sexType","label":"性别","width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(Number(row.sexType) === 1 ? '男' : '女')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"collegeName","label":"院系","width":"150"}}),_c('el-table-column',{attrs:{"prop":"majorName","label":"专业","width":"150"}}),_c('el-table-column',{attrs:{"prop":"managerUserName","label":"辅导员","width":"100"}}),_c('el-table-column',{attrs:{"prop":"className","label":"班级","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.className || '未绑定')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"campusName","label":"校区名称","width":"100"}}),_c('el-table-column',{attrs:{"prop":"apartmentName","label":"宿舍","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.dormitoryName && row.dormitoryBedNo)?_c('span',[_vm._v(_vm._s(row.dormitoryName)+_vm._s(row.roomName)+"室"+_vm._s(row.dormitoryBedNo)+"号床")]):(row.stuStatus===2)?_c('span',{staticClass:"font-grey"},[_vm._v("已毕业")]):_c('span',[_vm._v("未分配")])]}}])}),_c('el-table-column',{attrs:{"prop":"remarks","width":"200","label":"备注"}}),_c('el-table-column',{attrs:{"label":"操作","width":"220","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['stu:edit']),expression:"['stu:edit']"}],attrs:{"type":"primary","size":"mini","plain":""},on:{"click":function($event){return _vm.showStatusDialog(row)}}},[_vm._v("设置学籍状态 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['stu:edit']),expression:"['stu:edit']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){_vm.showDialog(row.id,((row.stuName) + "(" + (row.stuNo?row.stuNo:row.examineNo) + ")"))}}},[_vm._v("编辑 ")]),(false)?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['stu:del']),expression:"['stu:del']"}],attrs:{"size":"mini"},on:{"click":function($event){return _vm.del(_vm.deleteStudent,row.id,("" + (row.stuName)))}}},[_vm._v("删除 ")]):_vm._e()]}}])})],1),(_vm.visibleStatusDialog)?_c('set-student-status',{ref:"stuStatusDialogRef",attrs:{"visible":_vm.visibleStatusDialog},on:{"update:visible":function($event){_vm.visibleStatusDialog=$event},"on-close":_vm.renderTable}}):_vm._e(),(_vm.visibleDialog)?_c('student-dialog',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e(),(_vm.visibleOnlyReadDialog)?_c('student-only-read-dialog',{ref:"onlyReadDialogRef",attrs:{"visible":_vm.visibleOnlyReadDialog},on:{"update:visible":function($event){_vm.visibleOnlyReadDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }